import GdprElement from '../GdprElement';

/**
 * Name is just E[mbed]_500px because of the int as class name thing...
 */
class E_500px extends GdprElement {
    /**
     *
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();

        if (this.isLoaded()) {
            this.render();
        } else {

            this.loadScript('//500px.com/embed.js')
                .then(() => {
                    this.isLoaded(true);

                    this.render();

                    this.autoCheck();
                });
        }
    }

    render() {
        let html_img = ``;
        if (this.elem.dataset.img) {
            html_img = `<p>
                    <img src='${this.elem.dataset.img}' ${(this.elem.dataset.author ? 'alt="' + this.elem.dataset.author + '"' : '')}>
                </p>`;
        }

        let img = document.createElement('template');
        // build html for embed
        img.innerHTML = `<div class='pixels-photo'>
                    ${html_img}
                    <a href='${this.src}' ${(this.elem.dataset.author ? 'title="' + this.elem.dataset.author + '"' : '')}></a>
                </div>`;

        let content = this.getContent();
        content.appendChild(img.content.firstChild);

        // todo: add dispatching, scan makes all not rendered
        this.af.go('loaded');

        //console.log(window.pxembed);
        window.pxembed.scan();

        super.render();
    }

}

export default E_500px;