import GdprElement from '../GdprElement';

class Pinterest extends GdprElement {
    /**
     *
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();

        if (this.isLoaded()) {
            this.render();
        } else {
            // load the instagram sdk and let it create the global function instaBuild(<Node>) for recreating elements
            this.loadScript('//assets.pinterest.com/js/pinit.js', false, document.body, {'data-pin-build': 'instaBuild'})
                .then(() => {
                    this.isLoaded(true);

                    this.render();

                    this.autoCheck();
                });
        }
    }

    render() {
        switch (this.type) {
            case 'pin':
                this.createPin();
                break;
            case 'board':
                this.createBoard();
                break;
            case 'profile':
                this.createProfile();
                break;
        }
        /**
         * Is added when loading insta sdk above
         */
        window.instaBuild(this.getContent());

        super.render();
    }

    createPin() {
        // <a data-pin-do="embedPin" data-pin-width="medium" href="https://www.pinterest.com/pin/99360735500185749/"></a>
        let pin = document.createElement('a');
        pin.dataset.pinDo = 'embedPin';
        pin.dataset.pinWidth = this.elem.dataset.pinWidth || 'medium';
        pin.href = this.src;
        let content = this.getContent();
        content.appendChild(pin);

        // checks all 40ms if the pinterest item has already been loaded in this content container
        let load_interval = setInterval(() => {
            if ('undefined' !== typeof this.elem.querySelector(this.gdpr.selector.content).querySelector('[data-pin-id]')) {
                // check if the pinterest element exists in the content container
                this.af.go('loaded');

                clearInterval(load_interval);
            }
        }, 50);
    }

    createBoard() {
        // <a data-pin-do="embedBoard" data-pin-board-width="150" data-pin-scale-height="800" data-pin-scale-width="60" href="https://www.pinterest.com/pinterest/official-news/"></a>

        let pin = document.createElement('a');
        pin.dataset.pinDo = 'embedBoard';
        pin.dataset.pinBoardWidth = this.elem.dataset.pinBoardWidth || '150';
        pin.dataset.pinScaleHeight = this.elem.dataset.pinScaleHeight || '800';
        pin.dataset.pinScaleWidth = this.elem.dataset.pinScaleWidth || '60';
        pin.href = this.src;
        let content = this.getContent();
        content.appendChild(pin);

        // checks all 40ms if the pinterest item has already been loaded in this content container
        let load_interval = setInterval(() => {
            if ('undefined' !== typeof this.elem.querySelector(this.gdpr.selector.content).querySelector('[data-pin-id]')) {
                // check if the pinterest element exists in the content container
                this.af.go('loaded');

                clearInterval(load_interval);
            }
        }, 50);
    }

    createProfile() {
        // <a data-pin-do="embedUser" data-pin-board-width="400" data-pin-scale-height="240" data-pin-scale-width="80" href="https://www.pinterest.com/pinterest/"></a>

        let pin = document.createElement('a');
        pin.dataset.pinDo = 'embedUser';
        pin.dataset.pinBoardWidth = this.elem.dataset.pinBoardWidth || '400';
        pin.dataset.pinScaleHeight = this.elem.dataset.pinScaleHeight || '240';
        pin.dataset.pinScaleWidth = this.elem.dataset.pinScaleWidth || '80';
        pin.href = this.src;
        let content = this.getContent();
        content.appendChild(pin);

        // checks all 40ms if the pinterest item has already been loaded in this content container
        let load_interval = setInterval(() => {
            if ('undefined' !== typeof this.elem.querySelector(this.gdpr.selector.content).querySelector('[data-pin-id]')) {
                // check if the pinterest element exists in the content container
                this.af.go('loaded');

                clearInterval(load_interval);
            }
        }, 50);
    }
}

export default Pinterest;