import GdprElementIframe from '../GdprElementIframe';

class YouTube extends GdprElementIframe {
    /**
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();

        /*
         * Switch to rendered and transform iframe, handle after-render features (autocheck etc.)
         */
        this.render();
    }
}

export default YouTube;