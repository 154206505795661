/**
 * LazyLoading with either the IntersectionObserver or a very simple Polyfill
 * @example initialize a new LazyLoad and observe all `ul` that has not been `.rendered`, run the callback one-time
 * new LazyLoad({
 *         debug: true,
 *         onetime: true,
 *     },
 *     // callback when one element comes visible
 *     (elem) => {
 *         // do some heavy shit
 *
 *         // mark as rendered
 *         elem.classList.add('rendered');
 *     })
 *     // start observing all not already rendered items
 *     .observe(document.querySelectorAll('ul:not(.rendered)'));
 */
class LazyLoad {
    constructor(config, cb) {
        this.cb = cb;
        this.config = Object.assign({
            // defaults.
            debug: false,
            // if false the observer will not be canceled and run everytime, when true further executions of the observer are canceled when starting the first run
            onetime: false,
            polyfill: {}
        }, config);

        if (this.config.debug) {
            console.log('LazyLoad construct');
        }

        this.node_list = [];

        /**
         * tells if the polyfill is executed or IntersectionObserver is used
         * @type {boolean}
         */
        this.polyfill = true;
        if ('IntersectionObserver' in window &&
            'IntersectionObserverEntry' in window &&
            'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
            this.polyfill = false;
        }

        if (this.config.debug) {
            console.log('Executing ' + (this.polyfill ? 'simple Intersection polyfill' : ' IntersectionObserver') + '.');
        }

        if (!this.polyfill) {
            let option = {
                //root: document.querySelector('#scrollArea'),
                rootMargin: '0px',
                threshold: 0
            };
            this.observer = new IntersectionObserver(this.visibilityReached.bind(this), option);
        } else {
            // binding event handler that are needed for the polyfill
            window.addEventListener('resize', this.polyfillCheckVisibility.bind(this));
            window.addEventListener('scroll', this.polyfillCheckVisibility.bind(this));
        }

        return this;
    }

    /**
     * Start the observing of an NodeList (`querySelectorAll`)
     * @param {NodeList} entry
     */
    observe(entry) {
        entry.forEach((elem) => {
            if (!this.polyfill) {
                this.observer.observe(elem);
            } else {
                this.polyfillObserve(elem);
            }
        });
    }

    /**
     * Adds the entry to the observed array of the polyfill
     * @param elem
     */
    polyfillObserve(elem) {
        this.node_list.push({
            node: elem
        });
        // for initial check
        this.polyfillCheckVisibility();
    }

    /**
     * Checks all elements in the node_list for existence in the viewport, handles onetime run `unobserve` through an action-class and executes `this.visibilityReached`
     */
    polyfillCheckVisibility() {
        let visible_space = (window.innerHeight || document.documentElement.clientHeight) + window.pageYOffset;
        this.node_list.forEach((elem) => {
            let is_unobserved = false;
            if (this.config.onetime) {
                // unobserver for the polyfill
                if (elem.node.classList.contains('lazyloading--has-run')) {
                    is_unobserved = true;
                }
            }

            if (!is_unobserved) {
                let top = elem.node.getBoundingClientRect().top;

                if (top < visible_space) {
                    if (this.config.onetime) {
                        elem.node.classList.add('lazyloading--has-run');
                    }
                    if (this.config.debug) {
                        console.log('is visible:');
                        console.log(elem);
                    }
                    this.visibilityReached([{
                        target: elem.node,
                        isIntersecting: true
                    }])
                } else {
                    if (this.config.debug) {
                        console.log('needs ' + (top - visible_space) + ' until visible');
                    }
                }
            }
        });
    }

    polyfillUpdateList() {
        let tmp = [];
        this.node_list.forEach((elem) => {
            tmp.push({
                node: elem.node,
                top: elem.node.getBoundingClientRect().top
            });
        });
        this.node_list = tmp;
    }

    /**
     *
     * @param entries
     * @param observer
     */
    visibilityReached(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if (!this.polyfill) {
                    // cancel after first run
                    if (this.config.onetime) {
                        this.observer.unobserve(entry.target);
                    }
                }

                this.cb(entry.target);
            }
        });
    }
}

export default LazyLoad;