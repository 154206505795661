import GdprElement from '../GdprElement';

class Instagram extends GdprElement {

    /**
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();

        if (this.isLoaded()) {
            this.render();
        } else {
            this.loadScript('//www.instagram.com/embed.js')
                .then(() => {
                    this.isLoaded(true);

                    this.render();

                    this.autoCheck();
                });
        }
    }

    render() {
        this.createPost();
        super.render();
    }

    createPost() {
        if ('undefined' !== typeof instgrm) {
            let template = document.createElement('template');
            template.innerHTML = `<blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="https://www.instagram.com/p/${this.src}/" data-instgrm-version="8"></blockquote>`;
            this.getContent().appendChild(template.content.firstChild);

            instgrm.Embeds.process();
            /*twttr.widgets.createTweet(
                this.src,
                this.getContent(),
                () => {

                }
            );*/
            // todo: find correct way to get recognized when element is finished loading
            setTimeout(() => {
                this.af.go('loaded');
            }, 200);
        }
    }
}

export default Instagram;