import StateMachine from './StateMachine';

/**
 * Easier way of handling action classes, handles the removing and adding from classes when switching between states
 *
 * - relies on the `util/Array` for `Array.proto.diff()`
 *
 * @example get a new instance for closing and opening of a button, this removes and adds the needed classes on transition
 * let button_as = new AnimationFlow({
           node: document.querySelector('.nav-service'),
           debug: true,
           current: 'closed',
           class_prefix: '',
           states: {
               closed: {
                   class: []
               },
               opening: {
                   class: [
                       'opening',
                       'visible',
                   ]
               },
               open: {
                   class: [
                       'open',
                       'visible'
                   ]
               },
               closing: {
                   class: [
                       'closing',
                       'visible'
                   ]
               }
           }
   });
 * button_as.go('opening');
 * button_as.go('open');
 * button_as.go('closing');
 * button_as.go('closed');
 */
class AnimationFlow extends StateMachine {
    /**
     * Initialize a new animation state machine, uses object destruct, simply init with object
     *
     * @param node
     * @param debug
     * @param class_prefix
     * @param current
     * @param states
     * @param on
     * @param transition
     */
    constructor({node = undefined, debug = false, class_prefix = '', current = '', states = [], on = [], transition = []} = {}) {
        super({
            debug: debug,
            current: current,
            states: states,
            on: on,
            transition_stack: transition
        });
        this.node = node;
        this.class_prefix = class_prefix;
        this.onInterstateAll('leave', this.handleLeave.bind(this));
        this.onInterstateAll('enter', this.handleEnter.bind(this));
    }

    handleLeave(current, next) {
        if ('undefined' === typeof current.class) {
            current.class = [];
        }
        if ('undefined' === typeof next.class) {
            next.class = [];
        }

        // get those that are unique in the current state, so are not existing in the one where we are going
        let removing = current.class.diff(next.class);
        removing.forEach(remove => {
            console.log('is removing: ' + remove);
            this.node.classList.remove(remove);
        });
    }

    handleEnter(current, next) {
        if ('undefined' === typeof current.class) {
            current.class = [];
        }
        if ('undefined' === typeof next.class) {
            next.class = [];
        }
        let adding = next.class.diff(current.class);
        adding.forEach(add => {
            console.log('is adding: ' + add);
            this.node.classList.add(add);
        });
    }
}
export default AnimationFlow;