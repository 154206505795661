/**
 * Main class to build a gdpr double click
 */
class Gdpr {
    /**
     *
     * @param config
     */
    constructor(config) {
        this.config = Object.assign({
            // defaults.
            debug: false,
            // autoload controls if a barrier is displayed or the content is loaded directly
            autoload: false,
            selector: 'gdpr',
            // family wide config
            family: {}
        }, config);

        /**
         * The used selectors, easy BEM style css usage
         * @type {{element: string, rendered: string, content: string}}
         */
        this.selector = {
            element: '.' + this.config.selector,
            rendered: '.' + this.config.selector + '--rendered',
            content: '.' + this.config.selector + '--content',
            default: '.' + this.config.selector + '--default',
            barrier: '.' + this.config.selector + '--barrier'
        };

        /**
         * Contains
         * @type {{}}
         */
        this.element_registry = {};

        /**
         * A element handler must return a object that will handle the further loading and more, the element will be added into the element-registry, with `Gdpr.dispatch(family, cb)` could things be executed on all elements of one family
         *
         * @example add a new handler:
         *   this.element_handler[<family-id>] = callable;
         *
         * @type {{"google-maps": (function(*=, *): Maps)}}
         */
        this.element_handler = {
            /*'google-maps': (config, elem, gdpr) => new Maps(config, elem, gdpr)*/
        };
    }

    /**
     * Receives an element that will be made according to the data family and starts the execution
     *
     * @param elem
     */
    create(elem) {
        let family = elem.getAttribute('data-family') || false;
        if (false !== family) {
            if (family in this.element_handler) {

                // init element registry when not already done for family
                if (!(family in this.element_registry)) {
                    this.element_registry[family] = [];
                }

                /*
                 * Create a new element through calling the registered `element_handler` of a `family`,
                 * init the element with passing data to the constructor, data is the family specific config in gdpr init
                 * then add it to the registry and get the newly created element through accessing it with the from `push` returned (length - 1),
                 * an element can not be deleted after it was added.
                 */
                return this.element_registry[family][this.element_registry[family].push(
                    this.element_handler[family](
                        ('undefined' !== typeof this.config.family[family] ? this.config.family[family] : {}),
                        elem,
                        this
                    )
                ) - 1];
            } else {
                throw Error('Gdpr: no registered callback for family was found')
            }
        } else {
            console.log(elem);
            throw Error('Gdpr: could not find data attr `family`, can not create GdprElement class on previous logged Node');
        }
    }

    /**
     * Receives an element that will be made according to the data family and starts the execution
     *
     * @param family
     * @param cb
     * @param {Boolean} [excl_rendered] if the cb should also be executed on already rendered items, excludes rendered when true
     * @todo need of the implementation of a filter technique, e.g. cb not executing when element is already rendered, check is already hidden usw., maybe an `event singlerun` config table
     */
    dispatch(family, cb, excl_rendered = false) {
        if (
            'undefined' !== typeof this.element_registry[family] &&
            Array.isArray(this.element_registry[family])
        ) {
            this.element_registry[family].forEach(
                /**
                 * @param {GdprElement} e
                 */
                (e) => {
                    // execute the callback with `e` {GdprElement} as context
                    if ((!excl_rendered) || (excl_rendered && !e.rendered)) {
                        // double negative on `excl_rendered`
                        // only execute when the item has not already been loaded
                        cb.bind(e)();
                    }
                });
        }
    }

    /**
     *
     * @param node_id
     * @param family
     * @returns {Boolean}
     */
    isPrerendered(node_id, family) {
        if (
            'undefined' !== typeof this.config.family && 'undefined' !== typeof this.config.family[family] &&
            'undefined' !== typeof this.config.family[family].prerendered && false !== this.config.family[family].prerendered) {
            if (true === this.config.family[family].prerendered) {
                return true;
            }

            if (
                'undefined' !== typeof this.config.family[family].prerendered[node_id] &&
                false !== this.config.family[family].prerendered[node_id]
            ) {
                return this.config.family[family].prerendered[node_id];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * Says if the `family` should be loaded or a barrier must be displayed,
     * overwriting login on the config, a family specific overwrites the global
     *
     * @param family
     * @returns {Boolean}
     */
    isAutoload(family) {
        if ('undefined' !== typeof this.config.family && 'undefined' !== typeof this.config.family[family] && 'undefined' !== typeof this.config.family[family].autoload) {
            return this.config.family[family].autoload;
        } else if ('undefined' !== typeof this.config.autoload) {
            return this.config.autoload;
        } else {
            return false;
        }
    }
}

export default Gdpr;