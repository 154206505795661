import GdprElement from '../GdprElement';

class Tumbler extends GdprElement {
    /**
     *
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();

        if (this.isLoaded()) {
            this.render();
        } else {
            this.loadScript('//assets.tumblr.com/post.js', false, document.body)
                .then(() => {
                    this.isLoaded(true);

                    this.render();

                    this.autoCheck();
                });
        }
    }

    render() {
        switch (this.type) {
            case 'post':
                this.createPost();
                break;
        }
        super.render();
    }

    createPost() {
        // <div
        //         class="tumblr-post"
        //         data-href="https://embed.tumblr.com/embed/post/r_BTLFg8QzTPUMVSbe3vbg/107419673555"
        //         data-did="91445e02b350cbe0cb0c613fc0dc0d40c92f484a">
        //         <a href="https://staff.tumblr.com/post/107419673555/look-at-that-gif-up-above-thats-a-post">https://staff.tumblr.com/post/107419673555/look-at-that-gif-up-above-thats-a-post</a>
        //    </div>
        let post = document.createElement('div');
        post.classList.add('tumblr-post');
        post.dataset.href = this.src;
        post.dataset.did = this.elem.dataset.did || '';
        let content = this.getContent();
        content.appendChild(post);
        this.af.go('loaded');
    }
}

export default Tumbler;