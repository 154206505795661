import GdprElement from '../GdprElement';

/**
 * See: https://developers.facebook.com/docs/reference/javascript/FB.XFBML.parse
 */
class Facebook extends GdprElement {
    /**
     *
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();

        if (this.isLoaded()) {
            this.render();
        } else {
            let fb_root = document.createElement('div');
            fb_root.id = 'fb-root';
            document.body.appendChild(fb_root);

            this.loadScript('//connect.facebook.net/en_US/sdk.js', 'facebook-jssdk')
                .then(() => {
                    this.isLoaded(true);

                    FB.init({
                        //appId      : '{your-app-id}',
                        status: true,
                        xfbml: true,
                        version: 'v2.7' // or v2.6, v2.5, v2.4, v2.3
                    });

                    this.render();

                    this.autoCheck();
                });
        }
    }

    render() {
        if ('undefined' !== typeof FB) {
            let post = document.createElement('div');
            post.classList.add('fb-post');
            post.dataset.width = '500';
            post.dataset.href = this.src;
            let content = this.getContent();
            content.appendChild(post);

            FB.XFBML.parse(content, () => {
                // hmm...
            });

            this.af.go('loaded');

            super.render();
        }
    }

}

export default Facebook;