import GdprElement from '../GdprElement';

class GitHub extends GdprElement {
    /**
     *
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();

        this.isLoaded(true);

        this.render();
    }

    render() {
        switch (this.type) {
            case 'gist':
                this.createGist();
                break;
        }

        super.render();
        this.autoCheck();
    }

    createGist() {
        // generate global closure for handling the jsonp response
        let tmp_name = "fn" + Github_handleGist.cntr++;

        Github_handleGist[tmp_name] = (json) => {
            delete Github_handleGist[tmp_name];

            let template = document.createElement('template');
            template.innerHTML = json.div;
            this.getContent().appendChild(template.content.firstChild);

            // load the stylesheet onetime
            if (!GitHub.prototype.stylesheetLoaded) {
                let style = document.createElement('link');
                style.rel = 'stylesheet';
                style.type = 'text/css';
                style.href = json.stylesheet;
                document.getElementsByTagName('head')[0].appendChild(style);
                GitHub.prototype.stylesheetLoaded = true;
            }
        };

        this.loadScript(
            // load the gist from the json api instead of the js api for getting jsonp
            'https://gist.github.com/' + this.src + '.json?callback=Github_handleGist.' + tmp_name,
            this.src,
            this.getContent()
        ).then(() => {
            this.af.go('loaded');
        });
    }
}

/**
 * Global registry for JSONP handlers
 * @type {{cntr: number}}
 */
window.Github_handleGist = {cntr: 0};

GitHub.prototype.stylesheetLoaded = false;

export default GitHub;