/**
 * Returns the difference between two arrays
 *
 * @param array
 * @example
 *   let first = [1,2,3];
 *   let second = [2,3,4];
 *   first.diff(second);
 *   // 1
 *   second.diff(first);
 *   // 4
 *
 * @returns {*[]}
 */
Array.prototype.diff = function (array) {
    return this.filter((item) => {
        return array.indexOf(item) < 0;
    });
};