/**
 * A callback based state machine for unidirectional transitions.
 *
 * - has callbacks for all events that could occure during transitions
 * - has callbacks for specific transitions
 * - relies on `Array.proto.isArray()`, add a polyfill for older browser, general support is mostly sufficient

 * @example add states and transitions to a state machine and go from one to another
 * let sm = new StateMachine({
           node: document.querySelector('.nav-service'),
           debug: true,
           current: 'closed',
           class_prefix: '',
           states: {
               // here are the states defined
               closed: {
                   on: {
                       leaving(current, next) {
                           console.log('only executed when `leaving` closed');
                       },
                       leave(current, next) {},
                       leaved(current, next) {},
                       entering(current, next) {},
                       enter(current, next) {},
                       entered(current, next) {}
                   }
               },
               opening: {
                   on: {
                       entering(current, next) {
                           console.log('only executed when `entering` opening');
                       }
                   }
               },
               open: {},
               closing: {}
           },
           on: {
               leave(current, next) {
                   console.log('executed on every leave');
               },
               enter(current, next) {
                   console.log('executed on every enter');
               }
           },
           // the transition events are only done when exactly the same transition happens, the transition is the arrow
           transition: {
               'closed->opening': (current, next) => {
                   console.log('specific transition: closed-opening');
               },
               '<current.id>-><next.id>': (current, next) => {}
           }
   });
 * sm.go('opening');
 * sm.go('open');
 * sm.go('closing');
 * sm.go('close');
 *
 * @version 0.5.0
 */
class StateMachine {
    /**
     * Initialize a new animation state machine, uses object destruct, simply init with object
     *
     * @param debug
     * @param current
     * @param states
     * @param on
     * @param transition
     */
    constructor({debug = false, current = '', states = [], on = [], transition = []} = {}) {
        this.debug = debug;
        this.current = current;
        this.states = states;
        this.on = on;
        this.transition_stack = transition;
    }

    /**
     * Go to another state!
     *
     * @param next_id
     */
    go(next_id) {
        if (this.states[next_id]) {
            // get states of current
            let current = this.states[this.current];
            current.id = this.current;

            // get all states from
            let next = this.states[next_id];
            next.id = next_id;

            this.leaving(current, next);
            this.entering(current, next);

            this.leave(current, next);
            this.leaved(current, next);
            this.enter(current, next);

            this.transition(current, next);

            this.entered(current, next);


            // make the `next` the new current
            this.current = next_id;
        } else {
            throw Error('StateMachine: `next` is not found in `states`');
        }
        return this;
    }

    /**
     * Inter-state transition
     * - is executed within this class
     * @param current
     * @param next
     */
    leaving(current, next) {
        this.dispatch(current, next, 'leaving');
    }

    /**
     * Inter-state transition
     * - is executed within this class
     * @param current
     * @param next
     */
    leave(current, next) {
        this.dispatch(current, next, 'leave');
    }

    /**
     * Inter-state transition
     * - is executed within this class
     * @param current
     * @param next
     */
    leaved(current, next) {
        this.dispatch(current, next, 'leaved');
    }

    /**
     * Inter-state transition
     * - is executed within this class
     * @param current
     * @param next
     */
    entering(current, next) {
        this.dispatch(current, next, 'entering', 1);
    }

    /**
     * Inter-state transition
     * - is executed within this class
     * @param current
     * @param next
     */
    enter(current, next) {
        this.dispatch(current, next, 'enter', 1);
    }

    /**
     * Inter-state transition
     * - is executed within this class
     * @param current
     * @param next
     */
    entered(current, next) {
        this.dispatch(current, next, 'entered', 1);
    }

    /**
     * Dispatches the events of a particular step in the switching between states
     * - is executed within this class
     *
     * @param current
     * @param next
     * @param event_name
     * @param {int} type defines on which element the callback should be executed, 0 = execute on `current`, (not 0) = execute on `next`
     */
    dispatch(current, next, event_name, type = 0) {
        let exec = (0 === type ? current : next);

        if ('undefined' !== typeof exec.on && 0 < exec.on[event_name].length) {
            if (!Array.isArray(exec.on[event_name])) {
                exec.on[event_name] = [exec.on[event_name]];
            }
            exec.on[event_name].forEach(evt => {
                evt(current, next);
            });
        }

        if ('undefined' !== typeof this.on[event_name] && 0 < this.on[event_name].length) {
            if (!Array.isArray(this.on[event_name])) {
                this.on[event_name] = [this.on[event_name]];
            }
            this.on[event_name].forEach(evt => {
                evt(current, next);
            });
        }
    }

    /**
     * Execute a particular transition
     * - is executed within this class
     *
     * @param current
     * @param next
     */
    transition(current, next) {
        let tmp_id = current.id + '->' + next.id;
        if ('undefined' !== typeof this.transition_stack[tmp_id] && 0 < this.transition_stack[tmp_id].length) {
            if (!Array.isArray(this.transition_stack[tmp_id])) {
                this.transition_stack[tmp_id] = [this.transition_stack[tmp_id]];
            }
            this.transition_stack[tmp_id].forEach(evt => {
                evt(current, next);
            });
        }
    }

    /**
     * @example
     *   sm.onTransition('closed->opening', (current, next) => { });
     * @param transition
     * @param cb
     * @return {StateMachine}
     */
    onTransition(transition, cb) {
        if ('undefined' === typeof this.transition_stack[transition]) {
            this.transition_stack[transition] = [];
        }

        if (!Array.isArray(this.transition_stack[transition])) {
            this.transition_stack[transition] = [this.transition_stack[transition]];
        }
        this.transition_stack[transition].push(cb);
        return this;
    }

    /**
     * Register a new callback on a specific `state` and `transition`, transition is any valid inter-state transition
     * @example
     *   sm.onInterstate('closed', 'leaved', (current, next) => { });
     * @param state
     * @param transition
     * @param cb
     * @return {StateMachine}
     */
    onInterstate(state, transition, cb) {
        if (
            'undefined' === typeof this.states[state]) {
            this.states[state] = [];
        }
        if (
            'undefined' === typeof this.states[state].on) {
            this.states[state].on = [];
        }
        if (
            'undefined' === typeof this.states[state].on[transition]) {
            this.states[state].on[transition] = [];
        }

        if (!Array.isArray(this.states[state].on[transition])) {
            this.states[state].on[transition] = [this.states[state].on[transition]];
        }
        this.states[state].on[transition].push(cb);
        return this;
    }

    /**
     * Register a new callback on a specific inter-state transition, is executed on all transitions not depending on the state
     * @example
     *   sm.onInterstateAll('leaved', (current, next) => { });
     * @param transition
     * @param cb
     * @return {StateMachine}
     */
    onInterstateAll(transition, cb) {
        if ('undefined' === typeof this.on[transition]) {
            this.on[transition] = [];
        }
        if (!Array.isArray(this.on[transition])) {
            this.on[transition] = [this.on[transition]];
        }
        this.on[transition].push(cb);
        return this;
    }
}

export default StateMachine;