import GdprElement from '../GdprElement';

class CodePen extends GdprElement {
    /**
     *
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();

        if (this.isLoaded()) {
            this.render();
        } else {
            this.loadScript('//static.codepen.io/assets/embed/ei.js')
                .then(() => {
                    this.isLoaded(true);

                    this.render();

                    this.autoCheck();
                });
        }
    }

    render() {
        let pen = document.createElement('p');
        pen.classList.add('codepen');
        pen.dataset.themeId = this.elem.dataset.themeId;
        pen.dataset.slugHash = this.elem.dataset.slugHash;
        pen.dataset.user = this.elem.dataset.user;
        pen.dataset.defaultTab = this.elem.dataset.defaultTab;
        let content = this.getContent();
        content.appendChild(pen);
        __CPEmbed(content);
        this.af.go('loaded');

        super.render();
    }
}

export default CodePen;