import AnimationFlow from '../../../src/AnimationFlow';

class GdprElement {
    /**
     *
     * @param {*} config
     * @param {Node} elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        this.config = Object.assign({
            // defaults.
            debug: false,
            // auto_check dispatches the check accept on all items of the elements, after the first was accepted
            auto_check: false,
            // auto_check_load dispatches the loading of the items after the first check was accepted
            auto_check_load: false,
            prerendered: false,
        }, config);

        /**
         * @type {Node|HTMLElement}
         */
        this.elem = elem;
        /**
         * @type {Gdpr}
         */
        this.gdpr = gdpr;

        /**
         * If the element has already been rendered/loaded or a load has been started
         * @type {boolean}
         */
        this.rendered = false;

        /**
         * @type {AnimationFlow}
         */
        this.af = new AnimationFlow({
            node: this.elem,
            debug: this.config.debug,
            current: 'init',
            class_prefix: '',
            states: {
                init: {
                    class: []
                },
                'barrier-visible': {
                    class: [
                        'barrier-visible'
                    ]
                },
                'loading': {
                    class: [
                        'loading'
                    ]
                },
                'loaded': {
                    class: [
                        'content-loaded'
                    ]
                }
            }
        });
        /**
         * The platform organization family, you can dispatch on `family` or `type`
         * @type {string | boolean}
         */
        this.family = this.elem.getAttribute('data-family') || false;
        /**
         * The platform type, you can dispatch on `family` or `type`
         * @type {string | boolean}
         */
        this.type = this.elem.getAttribute('data-type') || false;
        /**
         * Contains the unique post ident, e.g.: src attr for iframe, id for tweet, embed code for gmaps
         * @type {string | boolean}
         */
        this.src = this.elem.getAttribute('data-src') || false;

        try {
            if (this.family &&
                this.type &&
                this.src &&
                this.gdpr) {

                // check if load content directly or show a barrier
                if (this.gdpr.isAutoload(this.family)) {
                    this.load();
                } else {
                    this.showBarrier();
                }
            } else {
                throw Error('GdprElement: not all needed data attr are set');
            }
        } catch (e) {
            if (this.config.debug) {
                console.error(e.message);
            }
        }
    }

    load() {
        this.af.go('loading');
    }

    showBarrier() {
        this.af.go('barrier-visible');

        let barrier = ``;

        if (false === this.gdpr.isPrerendered('barrier', this.family)) {
            // when not prerendered, generate the barrier on-the-fly
            barrier = document.createElement('div');
            barrier.classList.add(this.gdpr.selector.barrier);

            // todo: add html registry
            barrier.innerHTML = `
                <form>
                    <p>This will load data from somewhere.</p>
                    <button>Show!</button>
                </form>`;
            // push the created element into view
            this.elem.appendChild(barrier);
        } else {
            // when it is prerendered from backend
            barrier = this.elem.querySelector(this.gdpr.selector.barrier);
        }

        barrier.querySelector('form').onsubmit = (evt) => {
            evt.preventDefault();

            this.load();
        };
    }

    /**
     * Hides and accepts the 'checkbox' on the barrier form, adds `.hidden-check` to the barrier
     */
    hideCheck() {
        let barrier = this.elem.querySelector(this.gdpr.selector.barrier);
        barrier.classList.add('hidden-check');
        barrier.querySelector('input[type=checkbox]').checked = true;
    }

    /**
     * Returns the content node of the current element, handles `data-`|config overwrites, data overwrites js config
     * @returns {Node}
     */
    getContent() {
        if (this.elem.dataset.content) {
            return this.elem.querySelector(this.elem.dataset.content);
        } else {
            return this.elem.querySelector(this.config.selector.content);
        }
    }

    render() {
        /*
            todo: rendered is currently set when rendering/create has started, but not reset when error during loading has happened
         */
        this.rendered = true;
    }

    /**
     * Executes the features ` auto_check` and `auto_check_load`
     * @todo in conflict with LazyLoad and "out-of-screen:not-rendered" items
     */
    autoCheck() {
        if (this.config.auto_check) {
            this.gdpr.dispatch(this.family, this.hideCheck, true);
        }
        if (this.config.auto_check_load) {
            this.gdpr.dispatch(this.family, this.render, true);
        }
    }

    /**
     * Async loading a script into the browser and return a promise when done
     * @param script
     * @param {String|Boolean} [id]
     * @param {Node} [container]
     * @param {*} [attr] will be added as attributes on the script tag
     * @returns {Promise<any>}
     */
    loadScript(script, id = false, container = document.body, attr = {}) {
        return new Promise((resolve, reject) => {
            let elem = document.createElement('script');
            elem.type = 'text/javascript';
            elem.src = script;
            //elem.defer = true;
            for (let key in attr) {
                if (attr.hasOwnProperty(key)) {
                    elem.setAttribute(key, attr[key]);
                }
            }
            if (false !== id) {
                elem.id = id;
            }
            elem.addEventListener('load', () => resolve(script), false);
            elem.addEventListener('error', () => reject(script), false);
            container.appendChild(elem);
        });
    }

    /**
     * Checks if the active family is already loaded, e.g. after the SDK was loaded
     * @param {Boolean} set - will set the active family as loaded
     * @returns {Boolean}
     */
    isLoaded(set = false) {
        if (false !== set) {
            this.loaded.push(this.family);
        }
        return (this.family in this.loaded);
    }
}

/**
 * Static var for saving which platform has already be initialized
 *
 * @type {{}}
 *
 * @example add twitter after loading
 *
 *   this.loaded.push('twitter');
 *
 */
GdprElement.prototype.loaded = [];

export default GdprElement;