/**
 * Executes the callback after the content has been loaded, e.g. after all scripts has been loaded
 *
 * - needed when execution is relying on all scripts loaded (e.g. from CDNs)
 * - especially with `async` loaded scripts
 * - not when scripts are loaded with `defer`
 */
class Ready {
    /**
     * Registers event listeners for given callback
     * @param {function} cb
     */
    constructor(cb) {
        if (document.readyState !== 'loading') {
            cb();
        } else {
            document.addEventListener('DOMContentLoaded', cb);
        }
    }
}

export default Ready;