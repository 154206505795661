'use strict';

// Canal: Asset Ressources
import Ready from '../../../vendor/flood/canal-asset/src/Ready';
import LazyLoad from '../../../vendor/flood/canal-asset/src/LazyLoad';
//import TemplateFetch from '../../../vendor/flood/canal-asset/src/TemplateFetch';
import Gdpr from '../../../vendor/flood/canal-asset/module/gdpr/Canal-GDPR';
import Maps from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Maps';
import Twitter from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Twitter';
import Facebook from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Facebook';
import Instagram from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Instagram';
import YouTube from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/YouTube';
import Vimeo from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Vimeo';
import Spotify from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Spotify';
import Deezer from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Deezer';
import Office365 from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Office365';
import JSFiddle from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/JSFiddle';
import SassMeister from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/SassMeister';
import CodePen from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/CodePen';
import GitHub from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/GitHub';
import Giphy from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Giphy';
import Pinterest from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Pinterest';
import Tumblr from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/Tumblr';
import E_500px from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/E_500px';
import FakeEmbed from '../../../vendor/flood/canal-asset/module/gdpr/src/handler/FakeEmbed';

new Ready(() => {
    let gdpr = new Gdpr({
        debug: true,
        //selector: 'gdpr-autoload'
        autoload: false,
        family: {
            twitter: {
                debug: false,
                prerendered: true,
                auto_check: false,
                auto_check_load: true,
            },
            'google-maps': {
                //autoload: true,
                prerendered: true,
                auto_check: true
            },
            instagram: {
                //autoload: true,
                prerendered: true,
                auto_check: true
            },
            facebook: {
                prerendered: true
            },
            youtube: {
                prerendered: true
            },
            vimeo: {
                prerendered: true
            },
            spotify: {
                prerendered: true
            },
            deezer: {
                prerendered: true
            },
            office365: {
                prerendered: true
            },
            jsfiddle: {
                prerendered: true
            },
            sassmeister: {
                prerendered: true
            },
            codepen: {
                prerendered: true
            },
            github: {
                prerendered: true
            },
            giphy: {
                prerendered: true
            },
            pinterest: {
                prerendered: true
            },
            tumblr: {
                prerendered: true
            },
            '500px': {
                prerendered: true
            },
            'fake-embed': {
                prerendered: true
            }
        }
    });

    gdpr.element_handler = {
        'google-maps': (config, elem, gdpr) => new Maps(config, elem, gdpr),
        'twitter': (config, elem, gdpr) => new Twitter(config, elem, gdpr),
        'facebook': (config, elem, gdpr) => new Facebook(config, elem, gdpr),
        'instagram': (config, elem, gdpr) => new Instagram(config, elem, gdpr),
        'youtube': (config, elem, gdpr) => new YouTube(config, elem, gdpr),
        'vimeo': (config, elem, gdpr) => new Vimeo(config, elem, gdpr),
        'spotify': (config, elem, gdpr) => new Spotify(config, elem, gdpr),
        'deezer': (config, elem, gdpr) => new Deezer(config, elem, gdpr),
        'office365': (config, elem, gdpr) => new Office365(config, elem, gdpr),
        'jsfiddle': (config, elem, gdpr) => new JSFiddle(config, elem, gdpr),
        'sassmeister': (config, elem, gdpr) => new SassMeister(config, elem, gdpr),
        'codepen': (config, elem, gdpr) => new CodePen(config, elem, gdpr),
        'github': (config, elem, gdpr) => new GitHub(config, elem, gdpr),
        'giphy': (config, elem, gdpr) => new Giphy(config, elem, gdpr),
        'pinterest': (config, elem, gdpr) => new Pinterest(config, elem, gdpr),
        'tumblr': (config, elem, gdpr) => new Tumblr(config, elem, gdpr),
        '500px': (config, elem, gdpr) => new E_500px(config, elem, gdpr),
        'fake-embed': (config, elem, gdpr) => new FakeEmbed(config, elem, gdpr),
    };

    let use_lazyload = true;

    if (!use_lazyload) {
        console.time('creating_all_elements');

        document.querySelectorAll(gdpr.selector.element + ':not(' + gdpr.selector.rendered + ')')
            .forEach((elem) => {
                console.time('creating_single_element');
                gdpr.create(elem);
                console.timeEnd('creating_single_element');
            });

        console.timeEnd('creating_all_elements');
    } else if (use_lazyload) {
        console.time('creating_lazyload');
        new LazyLoad({
                debug: true,
                onetime: true,
            },
            // callback when one element comes visible
            (elem) => {
                console.time('creating_single_element');
                gdpr.create(elem);
                console.timeEnd('creating_single_element');
            })
        // start observing all not already rendered autoload items
            .observe(document.querySelectorAll(gdpr.selector.element + ':not(' + gdpr.selector.rendered + ')'));
        console.timeEnd('creating_lazyload');
    }
});