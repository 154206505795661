'use strict';

import $ from '../../bower_components/jQuery/dist/jquery.min';
// Canal: Asset Polyfill
import '../../vendor/flood/canal-asset/polyfill/object-assign';
import '../../vendor/flood/canal-asset/polyfill/node-html-foreach';
// Fetch Polyfill
// https://github.com/github/fetch
import '../../bower_components/fetch/fetch';
// Canal: Asset Utils
import '../../vendor/flood/canal-asset/util/Array';
// Canal: Asset Ressources
import Ready from '../../vendor/flood/canal-asset/src/Ready';
import './src/SocialMedia';
//import TemplateFetch from '../../vendor/flood/canal-asset/src/TemplateFetch';

//import Menu from './src/menu';

/*window.$ = $;
window.jQuery = $;*/

/**
 *
 * @type {TemplateFetch}
 */
/*window.tf = new TemplateFetch({
    host: window.location.protocol + '//' + window.location.host + '/',
    debug: true
});*/
new Ready(() => {
    //let menu = new Menu();
    //menu.bind();

    // Fetch and display the pre-rendered `view/templatefetch/overlay.twig`
    /*tf.fetch('overlay', {
        css: 'example-overlay',
        btn: 'Close',
        content: '<p>Some Content!</p>',
    }).then(function (response) {
        document.querySelector('.container--inner').insertAdjacentHTML('beforeend', response.html);
    });*/
});
