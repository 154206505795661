import GdprElement from './GdprElement';

class GdprElementIframe extends GdprElement {
    /**
     *
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();
    }

    render() {
        if ('undefined' !== typeof this.elem.dataset.transform && 'iframe' === this.elem.dataset.transform) {
            this.createIframe();
        }

        super.render();

        this.autoCheck();
    }

    /**
     * Creates out of the `data-src` attr a `iframe` inside of the `data-content`|`this.config.selector.config` node,
     * state goes to `loaded` when iframe is finished loading
     */
    createIframe() {
        /**
         * @type {GdprElement}
         */
        let self = this;
        let loaded = false;

        let iframe = document.createElement('iframe');
        iframe.onload = iframe.onreadystatechange = function () {
            if (!loaded && (!this.readyState || 'complete' === this.readyState)) {
                loaded = true;
                self.af.go('loaded');
            }
        };
        iframe.setAttribute('src', this.src);

        // check if there are `data-attr-*` which's values should be applied as attributes to the iframe
        for (let i = 0; i < this.elem.attributes.length; i++) {
            let attr = this.elem.attributes[i];
            if (/^data-attr-/.test(attr.nodeName)) {
                let attr_val = attr.nodeValue.split('|');
                if (attr_val[1]) {
                    // when name, value
                    iframe.setAttribute(attr_val[0], attr_val[1]);
                } else {
                    // when just value
                    iframe.setAttribute(attr_val[0], '');
                }
            }
        }
        this.getContent().appendChild(iframe);
    }
}

export default GdprElementIframe;