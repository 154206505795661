import GdprElement from '../GdprElement';

class Twitter extends GdprElement {
    /**
     *
     * @param config
     * @param elem
     * @param {Gdpr} gdpr
     */
    constructor(config, elem, gdpr) {
        super(config, elem, gdpr);
    }

    load() {
        super.load();

        if (this.isLoaded()) {
            this.render();
        } else {
            this.loadScript('//platform.twitter.com/widgets.js')
                .then(() => {
                    this.isLoaded(true);

                    this.render();

                    this.autoCheck();
                });
        }
    }

    render() {
        switch (this.type) {
            case 'tweet':
                this.createTweet();
                break;
            case 'timeline':
                this.createTimeline();
                break;
        }
        super.render();
    }

    createTweet() {
        if ('undefined' !== typeof twttr) {
            twttr.widgets.createTweet(
                this.src,
                this.getContent(),
                () => {
                    this.af.go('loaded');
                }
            );
        }
    }

    /**
     * Creates a User Timeline
     */
    createTimeline() {
        if ('undefined' !== typeof twttr) {
            twttr.widgets.createTimeline(
                {
                    sourceType: 'profile',
                    screenName: this.src
                },
                this.getContent(),
                () => {
                    this.af.go('loaded');
                }
            );
        }
    }

    createWidget() {
        if ('undefined' !== typeof twttr) {
            twttr.widgets.load();
            // todo: dispatch to family/type?
            this.af.go('loaded');
        }
    }
}

export default Twitter;
